<template>
  <div class="wrapper">
    <b-row class="upper-row">
      <b-col cols="6" class="mt-2">
        <p>Set the original campaign CPM</p>
      </b-col>
      <b-col cols="6">
        <b-form-input
          v-model="value"
          placeholder="Input ecmp"
          @input="calculator"
        ></b-form-input>
      </b-col>
    </b-row>

    <hr />
    <div class="down-row">
      <b-row class="align-center">
        <b-col cols="6">
          <p>eCMP</p>
        </b-col>
        <b-col cols="6">
          <h1 class="above-norm"><span>$ </span>{{ ecmp }}</h1>
        </b-col>
      </b-row>
      <b-row class="align-center mt-4">
        <b-col cols="6">
          <p>{{ $t("page.results.score.ecmp.calculateECMP.attentivePer000") }}</p>
        </b-col>
        <b-col cols="6" style="color: #7f7f7f">
          <h1>{{ attentiveSec }} <span> sec</span></h1>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>

export default {
  props:{
    efficiency:{
      required:true,
      type: Number,
    }
  },
  data() {
    return {
      ecmp: 0,
      attentiveSec: 0,
      value: 0,
    };
  },

  methods: {
    calculator() {
      console.log("Value you gave me: ", this.value);
      console.log("effeciency: ",this.efficiency)
      if(!this.value){
        this.ecmp=0
      }
      this.ecmp = this.efficiency * this.value;
      this.attentiveSec = 20;
    },
  },
  computed: {
    getECMP() {
      return this.ecmp;
    },
    getAttentiveSec() {
      return this.attentiveSec;
    },
  },
};
</script>
<style scoped>
.wrapper {
  background-color: #f8f9fa;
  border-radius: 5px;
}
.upper-row {
  padding: 20px 20px 0px 20px;
  display: flex;
  justify-content: space-around;
}
.down-row {
  padding: 20px 20px 30px 20px;
}

.above-norm {
  color: #7aae30;
}
.below-norm {
  color: #ff0000;
}
.close-norm {
  color: #f3ad57;
}
.align-center {
  align-items: center;
}
</style>
