<template>
  <div class="wrapper">
    <div class="title">
      <title-info condensed :info="this.tooltipInfo" size="large">
        {{ title }}
      </title-info>
    </div>
    <div class="bar-container">
      <div class="bar" v-for="index in 3" :key="index">
        <div style="height: 200px; position: relative;">
          <div
            class="bar-graph"
            :style="{
              height: `${percentage[index - 1].percentage}%`,
              
            }"
          ></div>
        </div>
        <div class="seconds text-center">
          <p>{{ percentage[index - 1].secondsWatched }} sec</p>
        </div>
        <div class="percentage text-center">
          <h4 class="">{{ percentage[index - 1].percentage }}%</h4>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TitleInfo from "./TitleInfo.vue";
export default {
  components: {
    TitleInfo,
  },
  data() {
    return {};
  },
  props: {
    percentage: {
      required: true,
    },
    title:{
      required:true
    },
    tooltipInfo:{
      required:true
    },
  },
};
</script>

<style scoped>
.title {
  width: 100%;
  display: flex;
  justify-content: center;
}

.wrapper {
  padding-left: 10px;
  padding-right: 10px;
}
.text-center {
  text-align: center;
}

.bar-graph {
  width: 42px;
  background-color: #004065;
  bottom: 0px;
  position: absolute;
}

.seconds {
  text-align: center;
  font: small;
  opacity: 0.8;
}
.bar-container {
  display: flex;
  justify-content: center;
  gap: 20px;
}
.bar {
  height: 100%;
  margin: 10px;
}
</style>
